import { addDays, formatISO } from 'date-fns';
import { graphql, navigate } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { feedback } from 'react-feedbacker';
import { UseFieldArrayReturn, useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from 'urql';

import { useCurrencies } from '@/bits';
import {
  Button,
  Card,
  CountryField,
  DateTimeField,
  ErrorMessage,
  InlineIconButton,
  LocalModal,
  NakedForm,
  PlayersSearch,
  SelectField,
  SelectOption,
  SubmitButton,
  TextField,
} from '@/components';
import { TrashIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { ChallengeRewardCreateForm } from '@/forms';
import {
  BrandEnum,
  ChallengeCriteria,
  ChallengeRewardInputV2,
  ChallengeRewardTypeEnum,
  EligibilityCriteria,
  Scalars,
} from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { useBrandValueOptions, useBrands } from '@/hooks/useBrands';
import { Nullable } from '@/types';
import {
  CreateChallengeMutation,
  CreateChallengeMutationVariables,
} from './__generated__/component';

export const Fragment = graphql`
  fragment SanityChallengeCreateBlock on SanityChallengeCreateBlock {
    title {
      ...SanityLocaleString
    }
    challengeCreated {
      ...SanityLocaleString
    }
    criteria {
      ...SanityLocaleString
    }
    addCriteria {
      ...SanityLocaleString
    }
    nameOfChallenge {
      ...SanityLocaleString
    }
    startDate {
      ...SanityLocaleString
    }
    endsDate {
      ...SanityLocaleString
    }
    brands {
      ...SanityLocaleString
    }
    market {
      ...SanityLocaleString
    }
    currency {
      ...SanityLocaleString
    }
    players {
      ...SanityLocaleString
    }
    createChallenge {
      ...SanityLocaleString
    }
    addReward {
      ...SanityLocaleString
    }
    criteriasSumary {
      ...SanityLocaleString
    }
    rewardsSummary {
      ...SanityLocaleString
    }
    complete {
      ...SanityLocaleString
    }
    spinsOn {
      ...SanityLocaleString
    }
    game {
      ...SanityLocaleString
    }
    percentageMatchRewardSummary {
      ...SanityLocaleString
    }
    freeSpinsRewardSummary {
      ...SanityLocaleString
    }
    moneyDropRewardSummary {
      ...SanityLocaleString
    }
    bonusMoneyDropRewardSummary {
      ...SanityLocaleString
    }
    percentageDynamicFreeSpinsRewardSummary {
      ...SanityLocaleString
    }
    percentageDynamicMoneyDropRewardSummary {
      ...SanityLocaleString
    }
    percentageDynamicBonusMoneyDropRewardSummary {
      ...SanityLocaleString
    }
  }
`;

const createChallengeMutation = gql`
  mutation CreateChallenge(
    $challengeName: String!
    $startDate: OffsetDateTime!
    $endDate: OffsetDateTime
    $countries: [String!]!
    $brands: [BrandEnum!]!
    $eligibility: [EligibilityCriteria!]!
    $challengeCriteria: [ChallengeCriteria!]!
    $challengeRewards: [ChallengeRewardInputV2!]!
    $assignedPlayers: [String!]!
    $currency: String!
  ) {
    createChallengeV3(
      challengeName: $challengeName
      startDate: $startDate
      endDate: $endDate
      countries: $countries
      brands: $brands
      eligibility: $eligibility
      challengeCriteria: $challengeCriteria
      challengeRewards: $challengeRewards
      assignedPlayers: $assignedPlayers
      currency: $currency
    )
  }
`;

type ChallengeCriteriaOptions = SelectOption & { value: ChallengeCriteria };

type Criteria = { type: ChallengeCriteria };

type PersistValuesType = {
  brands: BrandEnum[];
  countries: string[];
};

type FormValues = {
  challengeName: string;
  brands: BrandEnum[];
  countries: string[];
  criterias: Criteria[];
  criteria: ChallengeCriteria;
  rewards: ChallengeRewardInputV2[];
  startsAt: Date;
  endsAt?: Date;
  playerIds?: string[];
  currency: Scalars['ISOCurrencyCode']['input'];
};

const useOptions = () => {
  return useMemo(() => {
    const challengeCriteriaOptions: ChallengeCriteriaOptions[] = [
      {
        label: 'Complete SoW',
        value: ChallengeCriteria.CompleteSoW,
      },
      {
        label: 'Deposit Event',
        value: ChallengeCriteria.Deposit,
      },
    ];

    return {
      challengeCriteriaOptions,
    };
  }, []);
};

enum ModalType {
  Reward,
}

const getCriterias = (criterias: Criteria[]) => {
  return criterias.map((criteria) => criteria.type);
};

const getAssignedPlayers = (
  criteria: ChallengeCriteria,
  playerIds: string[] | undefined,
) => {
  if (criteria !== ChallengeCriteria.Deposit || !playerIds) {
    return [];
  }
  return playerIds;
};

const getEligibility = (criteria: ChallengeCriteria) => {
  switch (criteria) {
    case ChallengeCriteria.CompleteSoW:
      return [EligibilityCriteria.PendingSoW];
    case ChallengeCriteria.Deposit:
      return [];
    default:
      return [];
  }
};

const isSubmitDisabled = (
  isChallengeCreated: boolean,
  rewards: UseFieldArrayReturn<FormValues, 'rewards', 'id'>,
  criteria: ChallengeCriteria,
  players?: string[],
) => {
  if (isChallengeCreated || !rewards.fields.length) {
    return true;
  }
  if (criteria === ChallengeCriteria.Deposit && !players?.length) {
    return true;
  }
  return false;
};

const ChallengeCreateBlock: FC<{
  block: Queries.SanityChallengeCreateBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const [persistValues, setPersistValues] = useState<PersistValuesType>({
    brands: [],
    countries: [],
  });
  const { currencyOptions } = useCurrencies();
  const isMounted = useIsMounted();
  const { challengeCriteriaOptions } = useOptions();
  const [openedModalType, setOpenedModalType] = useState<ModalType | null>(
    null,
  );

  const { brands } = useBrands();
  const brandValueOptions = useBrandValueOptions(brands);

  const [createChallengeState, createChallenge] = useMutation<
    CreateChallengeMutation,
    CreateChallengeMutationVariables
  >(createChallengeMutation);

  const defaultValues: FormValues = {
    challengeName: '',
    brands: [],
    countries: [],
    criterias: [],
    criteria: ChallengeCriteria.CompleteSoW,
    rewards: [],
    playerIds: [],
    startsAt: new Date(),
    endsAt: addDays(new Date(), 14),
    currency: 'EUR' as Scalars['ISOCurrencyCode']['input'],
  };

  const methods = useForm<FormValues>({
    defaultValues,
  });

  const criterias = useFieldArray<FormValues, 'criterias'>({
    name: 'criterias',
    control: methods.control,
  });

  const getValues = methods.getValues;
  const setValue = methods.setValue;
  const removeCriteria = criterias.remove;

  const rewards = useFieldArray<FormValues, 'rewards'>({
    name: 'rewards',
    control: methods.control,
  });

  const criteria = methods.watch('criteria');
  const currency = methods.watch('currency');
  const players = methods.watch('playerIds');

  const isDepositCriteria = criteria === ChallengeCriteria.Deposit;

  // If user switches from SoW to Deposit, clear out values for Brand and Market, as they are not used in the deposit criteria.
  useEffect(() => {
    const brands = getValues('brands');
    const countries = getValues('countries');

    // Save form values for brand and countries.
    if (brands.length || countries.length) {
      setPersistValues({ brands, countries });
    }
    // clear form values if criteria is Deposit
    if (criteria === ChallengeCriteria.Deposit) {
      setValue('brands', []);
      setValue('countries', []);
    }
    // re-set form values criteria changes back to CompleteSoW
    if (criteria === ChallengeCriteria.CompleteSoW) {
      setValue('brands', persistValues.brands);
      setValue('countries', persistValues.countries);
    }
  }, [
    criteria,
    getValues,
    removeCriteria,
    setValue,
    persistValues.brands,
    persistValues.countries,
  ]);

  const onSubmit = (values: FormValues) => {
    const variables: CreateChallengeMutationVariables = {
      challengeName: values.challengeName,
      startDate: formatISO(values.startsAt),
      endDate: values.endsAt ? formatISO(values.endsAt) : null,
      countries: values.countries,
      brands: values.brands,
      eligibility: getEligibility(criteria),
      challengeCriteria: getCriterias(values.criterias),
      challengeRewards: values.rewards,
      currency: values.currency,
      assignedPlayers: getAssignedPlayers(criteria, values.playerIds),
    };

    return createChallenge(variables).then((res) => {
      if (res.error?.message && isMounted) {
        return setErrorMessage(res.error.message);
      }

      feedback.success(t(block.challengeCreated));
      navigate('/challenges');
    });
  };

  return (
    <Card size="lg" title={t(block.title)}>
      <LocalModal
        content={
          openedModalType === ModalType.Reward ? (
            <ChallengeRewardCreateForm
              onCompleted={(reward) => rewards.append(reward)}
              showDynamicRewards={isDepositCriteria}
              challengeCurrency={isDepositCriteria ? currency : null}
            />
          ) : null
        }
        ctx={{ close: () => setOpenedModalType(null) }}
        isOpen={openedModalType != null}
      />
      <div className="flex p-6">
        <NakedForm className="w-full" onSubmit={onSubmit} methods={methods}>
          <div className="flex sm:flex-row flex-col space-x-6 pb-6">
            <div className="flex-1 space-y-4">
              <SelectField
                name="criteria"
                id="ChallengeCreateBlock__criteria"
                title={t(block.criteria)}
                required
                options={challengeCriteriaOptions}
              />
              <Button
                type="button"
                variant="primary"
                disabled={criterias.fields.length > 0}
                onClick={() => {
                  criterias.append({ type: ChallengeCriteria[criteria] });
                }}
              >
                {t(block.addCriteria)}
              </Button>
            </div>
            <div className="flex-1 space-y-4">
              <TextField
                required
                name="challengeName"
                id="ChallengeCreateBlock__challengeName"
                title={t(block.nameOfChallenge)}
              />
              <div className="grid sm:grid-cols-2 gap-2">
                <DateTimeField
                  required
                  title={t(block.startDate)}
                  name="startsAt"
                  id="ChallengeCreateBlock__startDate"
                />
                <DateTimeField
                  title={t(block.endsDate)}
                  name="endsAt"
                  id="ChallengeCreateBlock__endDate"
                />
              </div>
              <SelectField
                required={!isDepositCriteria}
                name="brands"
                id="ChallengeCreateBlock__brands"
                title={t(block.brands)}
                options={brandValueOptions.options}
                isMulti
                allowSelectAll
                disabled={isDepositCriteria}
              />
              <CountryField
                required={!isDepositCriteria}
                title={t(block.market)}
                name="countries"
                id="ChallengeCreateBlock__countries"
                isMulti
                allowSelectAll
                disabled={isDepositCriteria}
              />
              {criteria === ChallengeCriteria.Deposit && (
                <>
                  <PlayersSearch
                    required={isDepositCriteria}
                    name="playerIds"
                    id="ChallengeCreateBlock__playerIds"
                    title={t(block.players)}
                  />
                  <SelectField
                    name="currency"
                    required
                    id="ChallengeCreateBlock__currency"
                    title={t(block.currency)}
                    options={currencyOptions}
                  />
                </>
              )}
            </div>
            <div className="flex-1">
              <div className="text-gray-900 dark:text-white">
                {t(block.criteriasSumary)}
              </div>
              <ul className="mt-2 list-disc text-gray-900 dark:text-white text-sm font-semibold truncate items-center">
                {criterias.fields.map((criteria, index) => {
                  return (
                    <li className="ml-8" key={criteria.id}>
                      {criteria.type}
                      <InlineIconButton
                        type="button"
                        onClick={() => criterias.remove(index)}
                      >
                        <TrashIcon />
                      </InlineIconButton>
                    </li>
                  );
                })}
              </ul>
              <div className="text-gray-900 dark:text-white">
                {t(block.rewardsSummary)}
              </div>
              <ul className="mt-2 list-disc text-gray-900 dark:text-white text-sm font-semibold truncate items-center">
                {rewards.fields.map((reward, index) => {
                  const rewardInput = reward.challengeRewardTypeInput;
                  return (
                    <li className="ml-8" key={reward.id}>
                      {rewardInput.challengeRewardType ===
                        ChallengeRewardTypeEnum.MoneyDropType &&
                        `${t(block.moneyDropRewardSummary, {
                          ...rewardInput.moneyDrop,
                        })}`}
                      {rewardInput.challengeRewardType ===
                        ChallengeRewardTypeEnum.FreeSpinsType &&
                        `${t(block.freeSpinsRewardSummary, {
                          ...rewardInput.freeSpins,
                        })}`}
                      {rewardInput.challengeRewardType ===
                        ChallengeRewardTypeEnum.BonusMoneyDropType &&
                        `${t(block.bonusMoneyDropRewardSummary, {
                          ...rewardInput.bonusMoneyDrop,
                        })}`}
                      {rewardInput.challengeRewardType ===
                        ChallengeRewardTypeEnum.DynamicFreeSpinsType &&
                        `${t(block.percentageDynamicFreeSpinsRewardSummary, {
                          ...rewardInput.dynamicFreeSpins,
                          multiplier: rewardInput.dynamicFreeSpins?.multiplier
                            ? rewardInput.dynamicFreeSpins?.multiplier * 100
                            : 0,
                        })}`}

                      {rewardInput.challengeRewardType ===
                        ChallengeRewardTypeEnum.DynamicMoneyDropType &&
                        `${t(block.percentageDynamicMoneyDropRewardSummary, {
                          ...rewardInput.dynamicMoneyDrop,
                          multiplier: rewardInput.dynamicMoneyDrop?.multiplier
                            ? rewardInput.dynamicMoneyDrop?.multiplier * 100
                            : 0,
                        })}`}

                      {rewardInput.challengeRewardType ===
                        ChallengeRewardTypeEnum.DynamicBonusMoneyDropType &&
                        `${t(
                          block.percentageDynamicBonusMoneyDropRewardSummary,
                          {
                            ...rewardInput.dynamicBonusMoneyDrop,
                            multiplier: rewardInput.dynamicBonusMoneyDrop
                              ?.multiplier
                              ? rewardInput.dynamicBonusMoneyDrop?.multiplier *
                                100
                              : 0,
                          },
                        )}`}
                      <InlineIconButton onClick={() => rewards.remove(index)}>
                        <TrashIcon />
                      </InlineIconButton>
                    </li>
                  );
                })}
              </ul>
              <Button
                className="mt-2"
                type="button"
                variant="primary"
                disabled={criterias.fields.length === 0}
                onClick={() => setOpenedModalType(ModalType.Reward)}
              >
                {t(block.addReward)}
              </Button>
            </div>
          </div>
          <ErrorMessage message={errorMessage} />
          <div className="flex justify-end">
            <SubmitButton
              value={t(block.createChallenge)}
              disabled={isSubmitDisabled(
                createChallengeState.fetching,
                rewards,
                criteria,
                players,
              )}
            />
          </div>
        </NakedForm>
      </div>
    </Card>
  );
};

export default ChallengeCreateBlock;
